import React from "react"
import { Link } from "gatsby"
import linkResolver from "../../utils/linkResolver"
import { IconButton } from "./../buttons"
import { IconArrowRight } from "./../svg"

export default ({
  data,
  title,
  image,
  className,
  prevClick,
  nextClick,
  currentSlide,
  totalSlides,
}) => {
  return (
    <div className={className}>
      <div className="slide__content">
        <div className="slide__content-inner">
          <div className="slide__index">
            {currentSlide} / {totalSlides}
          </div>
          <h2 className="slide__title">{title.text}</h2>
          <Link className="button" to={linkResolver(data.slider_item)}>
            Read edit
          </Link>

          <div className="slide__button-holder">
            <IconButton
              onClick={() => prevClick()}
              className="-large -pink -border-none"
              name="Previous"
            >
              <IconArrowRight className="icon -flip" />
            </IconButton>
            <IconButton
              onClick={() => nextClick()}
              className="-large -pink -border-none"
              name="Next"
            >
              <IconArrowRight />
            </IconButton>
          </div>
        </div>
      </div>

      <div className="slide__media">
        <div className="slide__button-holder">
          <IconButton
            onClick={() => prevClick()}
            className="-large -pink -border-none"
            name="Previous"
          >
            <IconArrowRight className="icon -flip" />
          </IconButton>
          <IconButton
            onClick={() => nextClick()}
            className="-large -pink -border-none"
            name="Next"
          >
            <IconArrowRight />
          </IconButton>
        </div>
        {image && (
          <picture>
            <source
              srcSet={image.thumbnails.tiny.url}
              media="(max-width: 420px)"
            />
            <source
              srcSet={image.thumbnails.small.url}
              media="(max-width: 568px)"
            />
            <source
              srcSet={image.thumbnails.medium.url}
              media="(max-width: 768px)"
            />
            <source
              srcSet={image.thumbnails.large.url}
              media="(max-width: 980px)"
            />
            <source
              srcSet={image.thumbnails.huge.url}
              media="(max-width: 1200px)"
            />
            <source
              srcSet={image.thumbnails.mega.url}
              media="(max-width: 1600px)"
            />
            <img
              src={image.url}
              alt=""
              className="slide__image"
              loading="lazy"
            />
          </picture>
        )}
      </div>

      {/* Renders a small preview of the slide's text */}
    </div>
  )
}
