import React, { useState, useEffect, useRef } from "react"
import Slide from "./Slide"

export default ({ data }) => {
  const sliderContent = data
  let [activeIndex, setActiveIndex] = useState(0)
  let [activeSlides, setActiveSlides] = useState([])
  let sliderContentLength = activeSlides.length - 1
  const timeoutRef = useRef(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const totalSlidesLength = () => {
    if (activeSlides.length < 10) {
      return `0${activeSlides.length}`.slice(-2)
    }

    return activeSlides.length
  }

  const prevClick = () => {
    if (activeIndex === 0) {
      setActiveIndex(sliderContentLength)
    } else {
      setActiveIndex(activeIndex - 1)
    }
  }

  const nextClick = () => {
    if (activeIndex === sliderContentLength) {
      setActiveIndex(0)
    } else {
      setActiveIndex(activeIndex + 1)
    }
  }

  useEffect(() => {
    let array = []
    sliderContent.forEach((slide) => {
      if (slide.slider_item && slide.slider_item !== null) {
        array = [...array, slide]
      }
    })
    setActiveSlides(array)
  }, [sliderContent])

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setActiveIndex((activeIndex) =>
          activeIndex === activeSlides.length - 1 ? 0 : activeIndex + 1
        ),
      5000
    )

    return () => {
      resetTimeout()
    }
  }, [activeIndex, activeSlides.length])

  return (
    <div className="slider" index={activeIndex}>
      {activeSlides.map((slide, index) => {
        const {
          title = "Swoon Love",
          hero_image,
        } = slide.slider_item.document.data

        const indexLength = () => {
          if (index + 1 < 10) {
            return `0${index + 1}`.slice(-2)
          }

          return index + 1
        }

        return (
          <Slide
            key={index}
            data={slide}
            title={title}
            image={hero_image}
            className={index === activeIndex ? "slide active" : "slide"}
            currentSlide={indexLength()}
            totalSlides={totalSlidesLength()}
            nextClick={nextClick}
            prevClick={prevClick}
          />
        )
      })}
    </div>
  )
}
