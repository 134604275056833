import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import {
  LatestFavsBanner,
  LatestEditsBanner,
  AboutMe,
  NewsletterSubscription,
  ShopMyInstagramBanner,
  NavigationBanner,
  Text,
  GoogleAdBanner,
} from "../components/slices"
import Slider from "../components/slider/Slider"

// Query for the Blog Home content in Prismic
export const query = graphql`
  query HomepageQuery {
    allPrismicHomepage {
      edges {
        node {
          data {
            title {
              text
            }
            description {
              text
            }
            slider {
              slider_item {
                link_type
                url
                type
                id
                uid
                document {
                  __typename
                  ... on PrismicEdit {
                    data {
                      title {
                        text
                      }
                      hero_image {
                        url(imgixParams: { q: 90 })
                        thumbnails {
                          huge {
                            url(imgixParams: { q: 90 })
                          }
                          medium {
                            url(imgixParams: { q: 90 })
                          }
                          mega {
                            url(imgixParams: { q: 90 })
                          }
                          small {
                            url(imgixParams: { q: 90 })
                          }
                          tiny {
                            url(imgixParams: { q: 90 })
                          }
                          large {
                            url(imgixParams: { q: 90 })
                          }
                        }
                      }
                    }
                    uid
                  }
                }
              }
            }
            body {
              __typename
              ... on PrismicHomepageBodyLatestEdits {
                slice_type
                slice_label
                primary {
                  latest_edits_banner_title {
                    text
                  }
                  latest_edits_banner_link {
                    link_type
                    url
                    uid
                    type
                  }
                }
              }
              ... on PrismicHomepageBodyNewsletterSubscription {
                slice_type
                slice_label
              }
              ... on PrismicHomepageBodyLatestFavs {
                primary {
                  __typename
                  latest_favs_banner_title {
                    text
                  }
                  latest_favs_banner_content {
                    text
                  }
                  latest_favs_banner_link {
                    __typename
                    url
                    uid
                    type
                  }
                }
                slice_label
                slice_type
              }
              ... on PrismicHomepageBodyNavigationBanner {
                primary {
                  navigation_pod_title {
                    text
                  }
                  navigation_banner_image1 {
                    url
                  }
                  navigation_banner_image_2 {
                    url
                  }
                  navigation_banner_image_3 {
                    url
                  }
                }
                slice_label
                slice_type
                items {
                  navigation_banner_link {
                    url
                    type
                    uid
                    document {
                      __typename
                      ... on PrismicEdit {
                        data {
                          title {
                            text
                          }
                        }
                      }
                      ... on PrismicCategory {
                        data {
                          title {
                            text
                          }
                        }
                      }
                      ... on PrismicEdit {
                        data {
                          title {
                            text
                          }
                        }
                      }
                      ... on PrismicLatestFavsListing {
                        data {
                          title {
                            text
                          }
                        }
                      }
                      ... on PrismicStandardContentPage {
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicHomepageBodyShopMyInstagramBanner {
                slice_type
                slice_label
                primary {
                  shop_my_instagram_banner_title {
                    text
                  }
                  snippet {
                    text
                  }
                  shop_my_instagram_banner_link {
                    __typename
                    ... on PrismicLinkType {
                      url
                    }
                    document {
                      ... on PrismicShopMyInstagram {
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicHomepageBodyAboutMe {
                slice_type
                slice_label
                primary {
                  about_me_banner_title {
                    text
                  }
                  about_me_banner_content {
                    text
                  }
                  image {
                    url
                  }
                  about_me_banner_link {
                    __typename
                    document {
                      ... on PrismicStandardContentPage {
                        uid
                        id
                        type
                      }
                    }
                  }
                }
              }
              ... on PrismicHomepageBodyGoogleAd {
                slice_type
              }
            }
          }
        }
      }
    }
  }
`

// Sort and display the different slice options
const HomepageSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.__typename) {
        case "rich_text":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<Text slice={slice} />}
            </div>
          )

        case "PrismicHomepageBodyLatestFavs":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<LatestFavsBanner slice={slice} />}
            </div>
          )

        case "PrismicHomepageBodyLatestEdits":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<LatestEditsBanner slice={slice} />}
            </div>
          )

        case "PrismicHomepageBodyNavigationBanner":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<NavigationBanner slice={slice} />}
            </div>
          )

        case "PrismicHomepageBodyShopMyInstagramBanner":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<ShopMyInstagramBanner slice={slice} />}
            </div>
          )

        case "PrismicHomepageBodyAboutMe":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<AboutMe slice={slice} />}
            </div>
          )

        case "PrismicHomepageBodyNewsletterSubscription":
          return (
            <div key={index} className="homepage-slice-wrapper">
              <div className="container">
                <NewsletterSubscription showImage={true} />
              </div>
            </div>
          )

        case "google_ad":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<GoogleAdBanner slice={slice} />}
            </div>
          )

        default:
          return <h1>Missing slice - {slice.slice_type}</h1>
      }
    })()
    return res
  })
}

export default ({ data }) => {
  // Define the Homepage content returned from Prismic
  const { allPrismicHomepage } = data
  const doc = allPrismicHomepage.edges.slice(0, 1).pop()
  const sliderContent = allPrismicHomepage.edges[0].node.data.slider
  const slices = allPrismicHomepage.edges[0].node.data.body

  if (!doc) return null

  return (
    <Layout className="homepage">
      <Slider data={sliderContent} />
      <HomepageSlices slices={slices} />
    </Layout>
  )
}
